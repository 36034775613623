<template>
  <div class="app-link news" ref="card" :data-height="elementHeight">
    <div class="card-image app-link--header">
      <img :src="item.image" :alt="item.header" />
    </div>
    <div class="card-content app-link--content">
      <h1 ref="header" class="subtitle">{{ item.header }}</h1>
      <p class="content__text" :class="clamp">{{ item.content }}</p>
      <button
        v-if="elementHeight >= totalHeight && !expanded"
        class="button is-text news-link"
        @click="expand"
      >
        {{ $t("ui.expand") }}
      </button>
      <a
        v-if="item.link && (!(elementHeight > totalHeight) || expanded)"
        class="button is-primary news-link"
        :href="item.link"
        target="_blank"
      >
        {{ $t("ui.blog") }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true
    },
    collapse: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      elementHeight: 0,
      headerHeight: 0,
      totalHeight: 240,
      expanded: false
    };
  },
  computed: {
    clamp() {
      return `clamp-${Math.ceil(this.totalHeight / (this.headerHeight + 24))}`;
    }
  },
  methods: {
    removeStyles() {
      this.$refs.card.style.height = "15rem";
      this.$refs.card.classList.remove("expand");
    },
    addStyles() {
      this.$refs.card.style.height = `${this.elementHeight + 42}px`;
      this.$refs.card.classList.add("expand");
    },
    expand() {
      if (!this.expanded) {
        this.addStyles();
        this.expanded = true;
      } else {
        this.removeStyles();
        this.expanded = false;
      }
    }
  },
  watch: {
    collapse() {
      this.removeStyles();
      this.expanded = false;
    }
  },
  mounted() {
    /** FLIP **/
    // Add class
    this.$nextTick(() => {
      this.$refs.card.classList.add("expand");
      // get Heights with expanded class
      this.elementHeight = this.$refs.card.clientHeight + 11;
      this.headerHeight = this.$refs.header.clientHeight;
      // Remove the class before it's rendered
      this.$refs.card.classList.remove("expand");
      this.$refs.card.style.height = "15rem";
    });
  }
};
</script>
<style lang="scss">
@import "~vars";
.app-link.news {
  transition: height 0.3s !important;

  .app-link--header {
    position: absolute;
    top: 0;
    padding: 0;
    background-color: transparent;
    overflow: hidden;
    height: 14.5rem;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .app-link--content {
    margin-top: 14.5rem;
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 100%;
    min-height: 15rem;
    padding-bottom: 0.5rem;
  }

  .content__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
  }

  .news-link {
    margin-top: auto;
    margin-right: auto;
  }

  &.expand {
    .content__text {
      -webkit-line-clamp: initial;
    }
  }
}
</style>
