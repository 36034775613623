<template>
  <carousel
    v-if="news.length > 0"
    class="card"
    :perPage="1"
    :paginationPadding="5"
    @pageChange="switchPage"
    :navigationEnabled="true"
  >
    <slide v-for="newsItem in news" :key="newsItem.id">
      <news-item :item="newsItem" :collapse="reset" />
    </slide>
  </carousel>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import NewsItem from "./NewsItem";
export default {
  data() {
    return {
      news: [],
      reset: 0
    };
  },
  methods: {
    switchPage() {
      this.reset++;
    }
  },
  created() {
    this.$http.get("/news").then(({ data }) => (this.news = data));
  },
  components: {
    Carousel,
    Slide,
    NewsItem
  }
};
</script>
